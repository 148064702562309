/** @jsx jsx */
import HomePageLayout from '../../components/HomePageLayout';
import { Col, Row } from '@fify.at/gatsby-theme-base/src/components/Grid/';
import { jsx } from 'theme-ui';
import React from 'react';
import Section from '@fify.at/gatsby-theme-base/src/components/Section/Section';
import Heading from '@fify.at/gatsby-theme-base/src/components/Heading/Heading';
import HeroSectionContainer from '@fify.at/gatsby-theme-base/src/components/HeroSection/HeroSectionContainer';
import PageTitle from '@fify.at/gatsby-theme-base/src/components/PageTitle/PageTitle';
import Subheading from '@fify.at/gatsby-theme-base/src/components/Subheading/Subheading';
import Divider from '@fify.at/gatsby-theme-base/src/components/Divider/Divider';
import { graphql } from 'gatsby';

export default function Impressum({ location, data }) {
  // console.log(data);
  return (
    <HomePageLayout
      location={location}
      lang="de"
      image={data.hero.childImageSharp.fluid}
      title="Impressum"
      header={{ attachment: 'default', appearance: 'default', fluid: true }}
    >
      {/*<HeroSectionContainer*/}
      {/*  fluid={true}*/}
      {/*  orientation="east"*/}
      {/*  variant="alpha"*/}
      {/*  title="WirKaufenDeinHaus"*/}
      {/*  subtitle="Impressum"*/}
      {/*  image={data.hero}*/}
      {/*/>*/}
      <Section container="small">
        <Heading title="Impressum" />
        <IntroContent />
      </Section>
    </HomePageLayout>
  );
}

function IntroContent() {
  // console.log(images)
  return (
    <>
      <h1>Impressum</h1>

      <p>
        <strong>BUYMYHOME24 GmbH</strong>
        <br />
        <strong>Gewerblicher An- und Verkauf von Immobilien</strong>
        <br />
        Obere Landstrasse 9
        <br />
        3500 Krems, Österreich
        <br />
        Firmenbuchnr.: 535083m
        <br />
        Gerichtsstand: Krems
      </p>

      <p>
        Tel: <a href="tel:+436601151400">+43 660 11 51 400</a>
        <br />
        E-Mail:{' '}
        <a href="mailto:office@wirkaufendeinhaus.at">
          office@wirkaufendeinhaus.at
        </a>
        <br />
        Website:{' '}
        <a href="https://wirkaufendeinhaus.at/">WirkaufendeinHaus.at</a>
        {/*<br />*/}
        {/*Die nach dem E-Commerce-Gesetz (ECG) bereitgestellten Informationen*/}
        {/*können direkt im{' '}*/}
        {/*<a href="http://firmen.wko.at/Web/DetailsKontakt.aspx?FirmaID=c7833a3e-e729-45c2-bdf4-15805170d159&StandortID=0&Suchbegriff=F%C3%BCrstl">*/}
        {/*  wko.at Firmen A-Z*/}
        {/*</a>{' '}*/}
        {/*abgerufen werden.*/}
        <br />
      </p>
      <p>
        <strong>Unternehmensgegenstand:</strong> Immobilien
        <br />
        <strong>Geschäftsführer:</strong> Mag. Thomas Fürstl
        <br />
        Mitglied der WKO
        <br />
        <a href="https://firmen.wko.at/wkdh/">Link zur WKO</a>
      </p>
      <p>
        Anwendbare berufsrechtliche Vorschriften sind insbesondere die
        Gewerbeordnung (zu finden unter{' '}
        <a href="http://www.ris.bka.gv.at">http://www.ris.bka.gv.at</a>)
      </p>

      <h2>MEDIENINHABER UND HERAUSGEBER</h2>

      <p>
        BUYMYHOME24 GmbH
        <br />
        <strong>Sitz:</strong> Obere Landstrasse 9, 3500 Krems
      </p>

      <h2>HAFTUNG FÜR INHALTE</h2>
      <p>
        Die Inhalte unserer Seiten wurden mit größter Sorgfalt erstellt. Für die
        Richtigkeit, Vollständigkeit und Aktualität der Inhalte können wir
        jedoch keine Gewähr übernehmen.
        <br />
        Als Diensteanbieter sind wir für eigene Inhalte auf diesen Seiten nach
        den allgemeinen Gesetzen verantwortlich. Diensteanbieter sind jedoch
        nicht verpflichtet, die von ihnen übermittelten oder gespeicherten
        fremden Informationen zu überwachen oder nach Umständen zu forschen, die
        auf eine rechtswidrige Tätigkeit hinweisen. Verpflichtungen zur
        Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche
        Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten
        Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden
        Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
      </p>

      <h2>HAFTUNG FÜR LINKS</h2>
      <p>
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren
        Inhalte wir keinen Einfluss haben. Deshalb können wir für diese fremden
        Inhalte auch keine Gewähr übernehmen. Für die Inhalte der verlinkten
        Seiten ist stets der jeweilige Anbieter oder Betreiber der Seiten
        verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte
        waren zum Zeitpunkt der Verlinkung nicht erkennbar. Eine permanente
        inhaltliche Kontrolle der verlinkten Seiten ist jedoch ohne konkrete
        Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei Bekanntwerden
        von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </p>

      <h2>COPYRIGHTS</h2>
      <p>
        Die Betreiber der Website sind bemüht, stets die Urheberrechte anderer
        zu beachten bzw. auf selbst erstellte sowie lizenzfreie Werke
        zurückzugreifen. Die durch die Seitenbetreiber erstellten Inhalte und
        Werke auf diesen Seiten unterliegen dem österreichischen Urheberrecht.
        Beiträge Dritter sind als solche gekennzeichnet. Die Vervielfältigung,
        Bearbeitung, Verbreitung und jede Art der Verwertung außerhalb der
        Grenzen des Urheberrechtes bedürfen der schriftlichen Zustimmung des
        jeweiligen Autors bzw. Erstellers. Downloads und Kopien dieser Seite
        sind nur für den privaten, nicht kommerziellen Gebrauch gestattet.
      </p>

      <h2>DATENSCHUTZ</h2>
      <p>
        Soweit auf unserer Website personenbezogene Daten (beispielsweise Name,
        Anschrift oder eMail-Adressen) erhoben werden, erfolgt dies, soweit
        möglich, stets auf freiwilliger Basis. Die Nutzung der Angebote und
        Dienste ist, soweit möglich, stets ohne Angabe personenbezogener Daten
        möglich. Der Nutzung von im Rahmen der Impressumspflicht
        veröffentlichten Kontaktdaten durch Dritte zur Übersendung von nicht
        ausdrücklich angeforderter Werbung und Informationsmaterialien wird
        hiermit ausdrücklich widersprochen. Die Betreiber der Seiten behalten
        sich ausdrücklich rechtliche Schritte im Falle der unverlangten
        Zusendung von Werbeinformationen, etwa durch Spam-Mails, vor.
      </p>
      <p>
        Falls nicht gesondert betitelt unterliegen alle Bilder, Illustrationen
        und Grafiken dem Copyright des Unternehmens.
      </p>

      <p>
        Bildquellen:
        <br />
        Bilder der Website werden von folgenden Bezugsquellen erworben:
        <br />
        - Shutterstock.com: Alexander Raths, ESB Professional, vtwinpixel,
        goodluz, Monkey Business Images, Antonio Guillem, Pressmaster, baranq,
        Rido, vectorfusionart, Darren Baker, Fabio Balbi, Brian A. Jackson,
        fizkes, Mind and I, Redpixel Pl
        <br />- DonauImmo.at
      </p>
    </>
  );
}

export const pageQuery = graphql`
  {
    hero: file(name: { eq: "hero-kontakt" }) {
      childImageSharp {
        fluid(maxWidth: 1280, quality: 90) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
